/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {FAQ} from "@components/seo/googleSearch";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h3: "h3",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(FAQ, {
    faq: [{
      q: "Why do I need luminosity masks?",
      a: "To keep control over image and color details when processing images."
    }, {
      q: "Why panel? I can build masks by hand",
      a: "Panel is much faster and has more features, like freerange color masks or color grading. It is also more accurate."
    }, {
      q: "Do I need to pay for updates?",
      a: "No, you buy ARCPanel once and get all future updates for free. Forever."
    }]
  }), "\n", React.createElement(_components.h1, {
    id: "faq",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#faq",
    "aria-label": "faq permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "FAQ"), "\n", React.createElement(_components.h3, {
    id: "do-i-really-need-luminosity-masks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#do-i-really-need-luminosity-masks",
    "aria-label": "do i really need luminosity masks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Do I really need luminosity masks?"), "\n", React.createElement(_components.p, null, "It depends. If you want quick one-button processing - probably not. For that, there are better plugins."), "\n", React.createElement(_components.p, null, "If you want to be in control over details and nuances of color and light when processing - luminosity masking is the best tool. This is what many famous landscape photographers use nowadays."), "\n", React.createElement(_components.h3, {
    id: "why-panel-i-can-build-masks-by-hand",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-panel-i-can-build-masks-by-hand",
    "aria-label": "why panel i can build masks by hand permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why panel? I can build masks by hand."), "\n", React.createElement(_components.p, null, "You can, and it's what I was doing for years myself. But times change. I started shooting more complex scenes, needed more blending. I also started printing my images and became more picky about details and quality. Features like freerange color masking you will not be able to do manually at all. Another big point is that ARCPanel is just many times faster than building masks manually, especially on large 50Mpx files."), "\n", React.createElement(_components.h3, {
    id: "do-i-need-to-pay-for-updates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#do-i-need-to-pay-for-updates",
    "aria-label": "do i need to pay for updates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Do I need to pay for updates?"), "\n", React.createElement(_components.p, null, "No, you buy ARCPanel once and get all future updates for free. Forever."), "\n", React.createElement(_components.h3, {
    id: "what-is-the-latest-version",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-the-latest-version",
    "aria-label": "what is the latest version permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is the latest version?"), "\n", React.createElement(_components.p, null, "ARCPanel 3.0 is the latest version in development. While you can still use ARCPanel 2.2, it is no longer actively supported."), "\n", React.createElement(_components.h3, {
    id: "where-can-i-run-my-copy-of-arcpanel-22",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#where-can-i-run-my-copy-of-arcpanel-22",
    "aria-label": "where can i run my copy of arcpanel 22 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Where can I run my copy of ARCPanel 2.2?"), "\n", React.createElement(_components.p, null, "When you enter your license key, ARCPanel connects with your Adobe Photoshop license. Everywhere your Photoshop license works ARCPanel will also work. Adobe now limits to 2 parallel installations of Photoshop, that means that you can run ARCPanel on second copy of Photoshop by installing it there and using the same licensing key."), "\n", React.createElement(_components.h3, {
    id: "where-can-i-run-my-copy-of-arcpanel-30",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#where-can-i-run-my-copy-of-arcpanel-30",
    "aria-label": "where can i run my copy of arcpanel 30 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Where can I run my copy of ARCPanel 3.0?"), "\n", React.createElement(_components.p, null, "For ARCPanel 3.0 you get 3 different licensing keys, making it possible to run panel on 3 computers at the same time. You can also deactivate the key and activate it on a different computer. Don't forget to do it if you no longer want to use the panel on current computer."), "\n", React.createElement(_components.h3, {
    id: "what-about-pirated-copies-of-photoshop",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-about-pirated-copies-of-photoshop",
    "aria-label": "what about pirated copies of photoshop permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What about pirated copies of Photoshop?"), "\n", React.createElement(_components.p, null, "ARCPanel 2.2 will work there too. But I don't provide support on pirated copies of Photoshop and can't guarantee there will be no issues. Also, you will not be able to run same copy of ARCPanel on different pirated installs. This is only possible with the licensed Photoshop."), "\n", React.createElement(_components.h3, {
    id: "what-if-i-re-install-adobe-photoshop",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-if-i-re-install-adobe-photoshop",
    "aria-label": "what if i re install adobe photoshop permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What if I re-install Adobe Photoshop?"), "\n", React.createElement(_components.p, null, "Deactivate your ARCPanel 3.0 licensing key before you uninstall Adobe Photoshop and activate it again after installation."), "\n", React.createElement(_components.h3, {
    id: "i-am-not-convinced-why-arcpanel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#i-am-not-convinced-why-arcpanel",
    "aria-label": "i am not convinced why arcpanel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "I am not convinced, why ARCPanel?"), "\n", React.createElement(_components.p, null, "It's fast, very easy to use and free. ARCPanel Pro provide lots of extra functionality if you want."), "\n", React.createElement(_components.h3, {
    id: "what-can-i-use-arcpanel-for",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-i-use-arcpanel-for",
    "aria-label": "what can i use arcpanel for permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What can I use ARCPanel for?"), "\n", React.createElement(_components.p, null, "Luminosity masks can help with exposure blends and retouch in landscapes, color grading in street and portraits, and ARCRetouch offers Frequency Separation and Dodge&Burn that are used in any style of photography."), "\n", React.createElement(_components.h3, {
    id: "which-adobe-photoshop-versions-are-supported",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#which-adobe-photoshop-versions-are-supported",
    "aria-label": "which adobe photoshop versions are supported permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Which Adobe Photoshop versions are supported?"), "\n", React.createElement(_components.p, null, "ARCPanel 2.2 runs great on Adobe Photoshop Creative Cloud 2015.5 and newer. Unfortunately, old versions of Photoshop are not supported. You can try running on manual Adobe Photoshop installations too, but I can not guarantee that panel will work 100%. And no, I won't be able to help if you have a pirated copy of Photoshop."), "\n", React.createElement(_components.p, null, "ARCPanel 3.0 works on Adobe Photoshop CC 2022 and newer."), "\n", React.createElement(_components.h3, {
    id: "how-is-arcpanel-different-to-lumenzia-tk-actions-or-adp-pro",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-is-arcpanel-different-to-lumenzia-tk-actions-or-adp-pro",
    "aria-label": "how is arcpanel different to lumenzia tk actions or adp pro permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How is ARCPanel different to Lumenzia, TK Actions or ADP Pro?"), "\n", React.createElement(_components.p, null, "ARCPanel is much easier to use and get started with. This is the key difference. Also,"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It's same or faster when building masks"), "\n", React.createElement(_components.li, null, "It's free"), "\n", React.createElement(_components.li, null, "Provides equally high quality"), "\n", React.createElement(_components.li, null, "Offers some unique features, like freerange color masks or true luminosity for ARCPanel Pro customers"), "\n"), "\n", React.createElement(_components.h3, {
    id: "still-why-build-another-panel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#still-why-build-another-panel",
    "aria-label": "still why build another panel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Still, why build another panel?"), "\n", React.createElement(_components.p, null, "I was using actions for masks for many years. It was quite slow and not very comfortable.\nAt some point got tired of actions inflexibility and decided to check some panels.\nI knew that luminosity masking isn't that complicated so I got very surprised when\nevery panel I checked looked more like an old TV remote."), "\n", React.createElement(_components.p, null, "This is when I decided to build ARCPanel, focusing on making it fast and very easy to use."), "\n", React.createElement(_components.h3, {
    id: "i-have-another-question",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#i-have-another-question",
    "aria-label": "i have another question permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "I have another question"), "\n", React.createElement(_components.p, null, "You can always send me an email to anton.averin.photo@gmail.com."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
